import { makeAutoObservable } from "mobx"

interface ILastConnectionDates {
    lastConnection?: string
    lastDisconnection?: string
}
interface IMessage {
    topic: string
}
interface IConnectionData {
    connected?: Map<string, boolean>
    connectionLog?: Map<string, ILastConnectionDates>
    queued?: Map<string, Array<IMessage>>
}

class State {
    public dateReceived: Date | null = null
    public connectionData: IConnectionData = {}

    constructor() {
        makeAutoObservable(this)
    }

    setDateReceived = (dateReceived: Date) => this.dateReceived = dateReceived;
    setConnectionData = (connectionData: IConnectionData) => this.connectionData = connectionData;
}

export { State, IConnectionData, ILastConnectionDates, IMessage }
