import React from 'react';
import 'bootswatch/dist/darkly/bootstrap.min.css';
import Container from 'react-bootstrap/Container';
import { Viewer } from './Viewer';
import { MqttStateHandler } from '../MqttStateHandler';
import { observer } from 'mobx-react-lite';

const mqttStateHandler = new MqttStateHandler();
const state = mqttStateHandler.state;

const App = observer(() => {
    return (
        <Container>
            <Viewer state={state} mqttPublisher={mqttStateHandler} />
        </Container>
    )
})
export { App }
