import * as mqtt from "mqtt"

enum MqttMessageType {
  STRING,
  JSON
}

interface IMqttRoute {
  topic: string;
  maybeHandle: (topic: string, payloads: PayloadHolder) => boolean;
}

class PayloadHolder {
  readonly #payloadCache: any;
  #stringPayloadCache: string;
  #jsonPayloadCache: any;

  constructor(payload: any) {
    this.#payloadCache = payload;
  }

  get payload(): any {
    return this.#payloadCache
  }

  get stringPayload(): string {
    if (undefined === this.#stringPayloadCache)
      this.#stringPayloadCache = this.#payloadCache.toString()
    if (undefined === this.#stringPayloadCache)
      this.#stringPayloadCache = ""
    return this.#stringPayloadCache
  }

  get jsonPayload(): any {
    if (undefined === this.#jsonPayloadCache)
      this.#jsonPayloadCache = JSON.parse(this.stringPayload)
    return this.#jsonPayloadCache
  }
}

class MqttRoute implements IMqttRoute {
  readonly topic: string;
  readonly regex: RegExp
  readonly messageType: MqttMessageType
  readonly recipient: (topic: string, matches: RegExpMatchArray, message: any) => void

  constructor(topic: string, regex: RegExp, messageType: MqttMessageType, recipient: (topic: string, matches: RegExpMatchArray, payload: any) => void) {
    this.topic = topic
    this.regex = regex
    this.messageType = messageType
    this.recipient = recipient
  }

  readonly maybeHandle = (topic: string, payloads: PayloadHolder): boolean => {
    if (this.regex === undefined)
      return false;
    const matches = this.regex.exec(topic)
    if (null === matches)
      return false;
    if (matches.length == 0)
      return false;
    this.recipient(topic, matches, this.messageType === MqttMessageType.JSON ? payloads.jsonPayload : payloads.stringPayload)
    return true;
  }
}

class SimpleMqttRoute implements IMqttRoute {
  readonly topic: string;
  readonly messageType: MqttMessageType
  readonly recipient: (payload: any) => void

  constructor(topic: string, messageType: MqttMessageType, recipient: (payload: AnimationPlayState) => void) {
    this.topic = topic
    this.messageType = messageType
    this.recipient = recipient
  }

  readonly maybeHandle = (topic: string, payloads: PayloadHolder): boolean => {
    if (topic != this.topic)
      return false;


    this.recipient(this.messageType === MqttMessageType.JSON ? payloads.jsonPayload : payloads.stringPayload)
    return true;
  }
}

class MqttRouter {
  readonly #client: mqtt.MqttClient
  readonly #routes: IMqttRoute[]

  constructor(url: string, routes: IMqttRoute[]) {
    const options = {
      clientId: "messaging-monitoring",
      clean: true,
      username: process.env.MQTT_USERNAME,
      password: process.env.MQTT_PASSWORD
    }
    const client = mqtt.connect(url, options);
    this.#client = client;
    this.#routes = routes;

    client.on('connect', () => { this.subscribeRoutes(); });
    client.on('message', (topic, message) => {
      const payloads = new PayloadHolder(message)
      this.#routes.forEach(route => {
        // Each route is its own error boundary, so that an error in one route's recipient doesn't prevent another one from running.
        try {
          route.maybeHandle(topic, payloads);
        }
        catch (exc) {
          // Log the error, but that's as far as it goes.  We don't want to stop anything running in what has to be a hardened process.
          console.error(exc)
        }
      })
    })
  }

  publish = (topic: string, message: string) => {
    this.#client.publish(topic, message, {qos: 2})
  }

  subscribeRoutes = () => {
    this.#routes.forEach(route => {
      this.#client.subscribe(route.topic, function (err) {
        if (err) {
          console.log("Couldn't subscribe to " + route.topic)
        }
      })
    })
  }
}

export { MqttRoute, MqttRouter, MqttMessageType, SimpleMqttRoute }