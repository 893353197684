import { MqttMessageType, MqttRouter, SimpleMqttRoute } from "./MqttRouter";
import { State } from "./State";

interface IMqttPublish {
    publish(topic: string, message: string): void;
}

interface IState {
    get state(): State;
}

class MqttStateHandler implements IMqttPublish, IState {
    #state: State;
    #router: MqttRouter

    constructor() {
        const mqttBrokerUrl = ((window.location.protocol === "https:") ? "wss://" : "ws://") + window.location.host + "/mqtt";
        
        this.#state = new State();
        let routes: SimpleMqttRoute[] = [
            new SimpleMqttRoute("queueState/value", MqttMessageType.JSON, (payload: any) => { this.#state.setConnectionData(payload); this.#state.setDateReceived(new Date()); })
        ]
        this.#router = new MqttRouter(mqttBrokerUrl, routes)
        this.#router.publish("queueState/get", "")
    }

    public publish = (topic: string, message: string) => {
        this.#router.publish(topic, message)
    }

    public get state() {
        return this.#state;
    }
}

export { MqttStateHandler, IMqttPublish, IState };
