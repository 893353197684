import React from 'react';
import Accordion from 'react-bootstrap/Accordion';
import Button from 'react-bootstrap/Button';
import Table from 'react-bootstrap/Table';
import { State, IMessage, IConnectionData } from '../State';
import { IMqttPublish } from '../MqttStateHandler';
import { observer } from 'mobx-react-lite';

interface IViewerProps {
    state: State
    mqttPublisher: IMqttPublish
}

const sanitiser = /^to\/[^/]+\/(.+)$/
function sanitiseTopic(topic: string): string {
    const result = topic.match(sanitiser);
    return result
        ? result[1]
        : topic;
}

interface IQueueViewerProps {
    queue?: Array<IMessage>
}

const QueueViewer = observer((props: IQueueViewerProps) => {
    return (
        props.queue == null
            ? "-"
            : <Accordion>
                <Accordion.Item eventKey="0">
                    <Accordion.Header>{props.queue.length}</Accordion.Header>
                    <Accordion.Body>
                        <Table striped bordered hover size="sm">
                            <tbody> { props.queue.map((message, index) => <tr key={index}><td>{sanitiseTopic(message.topic)}</td></tr>) }</tbody>
                        </Table>
                    </Accordion.Body>
                </Accordion.Item>
            </Accordion>
    )
})

const Viewer = observer((props: IViewerProps) => {
    const refreshClicked = () => {
        props.mqttPublisher.publish("queueState/get", "");
    }

    const units = {};
    if (props.state.connectionData.connected !== undefined) {
        for (const [key, value] of Object.entries(props.state.connectionData.connected)) {
            if (!(key in units))
                units[key] = {};
            const o = units[key];
            o.id = key;
            o.isConnected = value;
        }
    };
    if (props.state.connectionData.connectionLog !== undefined) {
        for (const [key, value] of Object.entries(props.state.connectionData.connectionLog)) {
            if (!(key in units))
                units[key] = {};
            const o = units[key];
            o.id = key;
            o.lastConnection = value.lastConnection;
            o.lastDisconnection = value.lastDisconnection;
        }
    };
    if (props.state.connectionData.queued !== undefined) {
        for (const [key, value] of Object.entries(props.state.connectionData.queued)) {
            if (!(key in units))
                units[key] = {};
            const o = units[key];
            o.id = key;
            o.queue = value;
        }
    };
    const sortedIds = Object.keys(units).sort();

    return (
        <>
            <h1>Hub connections as at {props.state.dateReceived == null ? "(never)" : props.state.dateReceived.toUTCString()} <Button variant="primary" onClick={refreshClicked}>Refresh</Button></h1>
            <Table striped bordered hover>
            <thead>
                <tr>
                    <th>ID</th>
                    <th>Connected?</th>
                    <th>Last connected (UTC)</th>
                    <th>Last disconnected (UTC)</th>
                    <th>Queue length</th>
                </tr>
            </thead>
            <tbody>
                {sortedIds.map((id) => (
                    <tr key={id}>
                        <td>{id}</td>
                        <td>{units[id].isConnected ? 'Yes' : ''}</td>
                        <td>{units[id].lastConnection == null ? '' : new Date(Date.parse(units[id].lastConnection)).toUTCString()}</td>
                        <td>{units[id].lastDisconnection == null ? '' : new Date(Date.parse(units[id].lastDisconnection)).toUTCString()}</td>
                        <td><QueueViewer queue={units[id].queue} /></td>
                    </tr>))
                }
            </tbody>
            </Table>
        </>
    );
})
export { Viewer }